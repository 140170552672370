import React from 'react'

export default function AccomodationOne() {
    return (
        <div className='container' >
            <div className='row' >
                <div className='col' >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Accomodation</span></h1>
                    </div>
                </div>
            </div>
            <div className='row' >
                <div className='col about-store-text' >
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col' >
                    <h6 className="about-two-title" >Our Facilities</h6>
                </div>
            </div>
            <div className='row' >
                <div className='col' >
                    <ul className='about-two-ul'>
                        <li>To propagate authentic &amp; shastroktha knowledge of ayurveda, ayurveda panchakarma, and rejuvenation therapies.</li>
                        <li> To treat various diseases &amp; disorders using the basic principles of ayurveda and Panchakarma therapy.</li>
                        <li> To improve the quality of life for those with chronic ailments. </li>
                        <li> To cure those various ailments where the cure is not possible in other fields of medicine, but only through research in ayurveda. </li>
                        <li> To educate the common man and disseminate knowledge treasure of ayurveda- the mother of all the sciences. </li>
                        <li> To conduct research and educational activities in understanding and advancing in the treatment of those chronic and debilitating conditions through research in ayurveda and  </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
