import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function AboutTwo() {
    return (
        <Container>
            <Row>
                <Col> <h6 className="about-two-title" >Our Values</h6> </Col>
            </Row>
            <Row>
                <Col>
                    <ul className='about-two-ul'>
                        <li>To propagate authentic &amp; shastroktha knowledge of ayurveda, ayurveda panchakarma, and rejuvenation therapies.</li>
                        <li> To treat various diseases &amp; disorders using the basic principles of ayurveda and Panchakarma therapy.</li>
                        <li> To improve the quality of life for those with chronic ailments. </li>
                        <li> To cure those various ailments where the cure is not possible in other fields of medicine, but only through research in ayurveda. </li>
                        <li> To educate the common man and disseminate knowledge treasure of ayurveda- the mother of all the sciences. </li>
                        <li> To conduct research and educational activities in understanding and advancing in the treatment of those chronic and debilitating conditions through research in ayurveda and  </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}
