import React from 'react'
import { Carousel } from 'react-bootstrap'
import SlideData from '../../../Data/HomeData/Slide.json'
import {Link} from "react-router-dom"

export default function Slider() {
    return (
        <div>
            <Carousel fade>
                {
                    SlideData.map((data, key) => (
                        <Carousel.Item key={key}>
                            <img
                                className='slide-carl'
                                src={data.image}
                                alt="Loading..."
                            />
                            <Carousel.Caption>
                                <h5 className='slide-cap' > {data.title} </h5>
                                <div className='slide-cap-links' >
                                <Link to={data.link1} className='slide-link'> {data.link} </Link>
                                <Link to={data.link2} className='slide-link1'> {data.otherlink} </Link>
                            </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))
                }

            </Carousel>
        </div>
    )
}
