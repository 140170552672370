import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Neuro1 from '../Container/Neurotreatment/Neuro1'
import Neuro2 from '../Container/Neurotreatment/Neuro2'
import Neuro3 from '../Container/Neurotreatment/Neuro3'
import Neuro4 from '../Container/Neurotreatment/Neuro4'



export default function ArthoCare() {
    return (
       
      <React.Fragment>
      <SEO title="Kalpataru || Neurocare"  />
      <TopNav/>
      <Breadcrumb image="images/about/herb.jpg" title="Home" content="Neurocare"/>
     <Neuro1/>
     <Neuro2/>
     <Neuro3/>
     <Neuro4/>
      <Footer/>
     </React.Fragment>

       
    )
}
