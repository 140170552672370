import React from 'react'
import Data from '../../../Data/Events/ExpoTwo.json'

function ExpoTwo() {
    return (
        <div className='container' >
            <div className='row' >
                <div className='col text-center mb-3' >
                    <h4> HERE'S WHY YOU SHOULD BE AT AYUR EXPO 2022 </h4>
                </div>
            </div>
            {
                Data.map((d) => (
                    <div className='row' >
                        <div className='col expo_col' >
                            <h2 className='expo_one_title'> {d.title} </h2>
                            <p> {d.des} </p>
                        </div>
                    </div>
                ))
            }

        </div>
    )
}

export default ExpoTwo