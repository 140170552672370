import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Data from "../../../Data/Events/YogaCampTwoData.json"

function YogaCampTwo() {
    return (
        <div className='container' >
            <div className='row mb-5' >
                <div className='col' >
                    <h4 className='yoga_two_title'> Packages we offer </h4>
                </div>
            </div>
            <div className='row mb-5' >
                {
                    Data.map((d) => (
                        <div className='col-lg-3 yoga_btn' >
                            <p className='text-center yoga_col '>
                                <strong> {d.title} </strong>
                                <br />
                                <strong> {d.titleOne} </strong>
                                <br />
                                {d.des}, <br /> {d.desOne}
                            </p>
                            <h3 className='text-center yoga_camp_two_price '> {d.price} </h3>
                            <Button className='expo_one_btn yoga_btn' >Book Now</Button>
                        </div>
                    ))
                }

            </div>
            <Row>
                <Col> <h6 className="about-two-title" >Our Values</h6> </Col>
            </Row>
            <Row>
                <Col xl={5}>
                    <ul className='about-two-ul'>
                        <li>07:00 Satsang, meditation, chanting, and mantras.</li>
                        <li> 08:00 Yoga Asana class.</li>
                        <li> 09:30 Breakfast </li>
                        <li> 10:45 Yoga Posture Alignment ( Paid optional) </li>
                        <li> 13:00 Lunch  </li>
                        <li> 14:00 Philosophy </li>
                        <li> 16:00 Yoga Asana class </li>
                        <li> 18:00 Dinner </li>
                        <li> 19:30 Meditation or yoga Nidra </li>
                        <li> 22:00 Lights out </li>
                    </ul>
                </Col>
                <Col xl={6}>
                    <img className='yoga_camp_img' src='images/events/yoga/yogafive.jpg' alt='Loading..' />
                </Col>
            </Row>
        </div>
    )
}

export default YogaCampTwo