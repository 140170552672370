import React from 'react'
import AboutStoreData from '../../../Data/AboutData/AboutStore.json'

export default function AboutStore() {
    return (
        <div className='container' >
            <div className='row' >
                <div className='col' >
                    <div className="section_heading">
                        <h1>Kalpataru <span>Ayurvedic</span> Stores</h1>
                    </div>
                    {/* <h3> Ayurvedic Stores For Every One </h3> */}
                </div>
            </div>
            <div className='row' >
                <div className='col about-store-text' >
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    </p>
                </div>
            </div>
            <div className='row mt-3 mb-5' >
                {
                    AboutStoreData.map((d) => (
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-10 col-xs-10 pb-1' >
                            <div className="card bg-dark text-white">
                                <img src={d.image} className="about-store-img" alt="..." />
                                <div className="card-img-overlay">
                                    <h5 className="card-title about-store-title-one"> {d.title} </h5>
                                    <p className="card-text about-store-text-one">{d.text}</p>
                                    <p className="card-text">Last updated 3 mins ago</p>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}
