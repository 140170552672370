import React from 'react'

function BlogOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>Kalpataru <span> Blogs </span> Reviews</h1>
            </div>
            <div className='row' >
                <div className='col' >

                </div>
            </div>
        </div>
    )
}

export default BlogOne