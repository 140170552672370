import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import ProductOne from '../Container/BuyProductContainer/ProductOne'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function BuyProducts() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Buy Products" />
            <TopNav />
            <Breadcrumb image="images/about/herb.jpg" title="Home" content="Buy Products" />
            <ProductOne/>
            <Footer />
        </React.Fragment>
    )
}
