import React from 'react'

function ShishuSwarnaTwo() {
    return (
        <div className='container' >
            <div className='row mb-5' >
                <div className='col' >
                    <img className='yoga_camp_img' src='images/events/swarna/swarna-bindu.jpg' alt='Loading..' />
                </div>
            </div>
            <div className='row' >
                <h3 className='about-two-title'> Benifits </h3>
                <div className='col about-two-ul' >
                    <ul>
                        <li> Certain action of stars will always be there on the things. </li>
                        <li> Purchasing of gold on akshaya tritiya. </li>
                        <li> Relation on deciding the full moon day for Ramzan. </li>
                        <li> In the same way pushami </li>
                    </ul>
                </div>
            </div>
            <div className='row' >
                <div className='col' >
                    <p>
                        Ayurveda is known for its own specialties. Today the whole world is affected with different diseases.
                        Modern medicines fail to treat the patients satisfactorily in sprite of many researches and latest technologies, because of their hazardous side effects.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col' >
                    <p>
                        Ayurveda the science of life, provides many other alternative ways to tackle with such disorders successfully.
                        Not only treating unhealthy individuals, Ayurveda mainly helps to maintain the healthy state of mind and body. Ayurveda holds good even today though it was written thousands of years ago.
                    </p>
                </div>
            </div>
            <div className='row' >
                <div className='col' >
                    <p>
                        We all know that Ayurveda is been an ancient practice of medicine which aims at “Protecting the health of healthy person, and curing the unhealthy”.
                        Working on the same objective we would like to introduce you “Swarna Bindu Prashana”.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ShishuSwarnaTwo