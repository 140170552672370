import React from 'react'
export default function Beauty2() {
    return (

<div>
{/* <!-- Beatycare--> */}
<div  className="beautycare pt-3 pb-3">
<div  className="container">
<div  className="row">

<div  className="col-md-6">
<h2 className="beautyheading">kalpa soundarya</h2>
<p  className="mt-3"><b>Heading1</b> Lorem lorm lorem lorme lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorme lorem lorem lorem .</p>
<p><b>Heading2</b>   Lorem lorm lorem lorme lorem lorem lorem lorem lorem lorem lorem lorem lorme lorme lorem lorem lorem lorem lorem.</p>
<div className="media">
  <span className="result">80%</span>
  <div className="media-body">
    <p>result of the  lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem.</p>
  </div>
</div>
</div>
<div  className="col-md-6">
<img src="https://shathayu.com/wp-content/uploads/2016/07/Beauty-care.jpg"  className="img-fluid"/>
</div>

</div>
</div>
</div>

<div  className="beautycare pt-3 pb-3">
<div  className="container">
<div  className="row">

<div  className="col-md-6">
<img src="https://shathayu.com/wp-content/uploads/2016/07/Beauty-care.jpg"  className="img-fluid"/>
</div>
<div  className="col-md-6">
<h2 className="beautyheading">Natural Beatycare</h2>
<p  className="mt-3"><b>Heading1</b> Lorem lorm lorem lorme lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorme lorem lorem lorem .</p>
<p><b>Heading2</b>   Lorem lorm lorem lorme lorem lorem lorem lorem lorem lorem lorem lorem lorme lorme lorem lorem lorem lorem lorem.</p>
<div className="media">
  <span className="result">80%</span>
  <div className="media-body">
    <p>result of the  lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem.</p>
  </div>
</div>
</div>

</div>
</div>
</div>
<div  className="beautycare pt-3 pb-3">
<div  className="container">
<div  className="row">

<div  className="col-md-6">
<h2 className="beautyheading">Hearbal Beautycare</h2>
<p  className="mt-3"><b>Heading1</b> Lorem lorm lorem lorme lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorme lorem lorem lorem .</p>
<p><b>Heading2</b>   Lorem lorm lorem lorme lorem lorem lorem lorem lorem lorem lorem lorem lorme lorme lorem lorem lorem lorem lorem.</p>
<div className="media">
  <span className="result">80%</span>
  <div className="media-body">
    <p>result of the  lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem.</p>
  </div>
</div>
</div>
<div  className="col-md-6">
<img src="https://shathayu.com/wp-content/uploads/2016/07/Beauty-care.jpg"  className="img-fluid"/>
</div>

</div>
</div>
</div>
<div  className="beautycare pt-3 pb-3">
<div  className="container">
<div  className="row">

<div  className="col-md-6">
<img src="https://shathayu.com/wp-content/uploads/2016/07/Beauty-care.jpg"  className="img-fluid"/>
</div>
<div  className="col-md-6">
<h2 className="beautyheading">Medicated Beatycare</h2>
<p  className="mt-3"><b>Heading1</b> Lorem lorm lorem lorme lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorme lorem lorem lorem .</p>
<p><b>Heading2</b>   Lorem lorm lorem lorme lorem lorem lorem lorem lorem lorem lorem lorem lorme lorme lorem lorem lorem lorem lorem.</p>
<div className="media">
  <span className="result">80%</span>
  <div className="media-body">
    <p>result of the  lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem.</p>
  </div>
</div>
</div>

</div>
</div>
</div>


 {/* <!-- Beautycare--> */}

</div>
          
     
    )
}
