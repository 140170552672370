import React from 'react'

function ProductOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>Buy <span> Products </span> Online</h1>
            </div>
            <div className='row' >
                <div className='col' >

                </div>
            </div>
        </div>
    )
}

export default ProductOne