import React from 'react'
import { Link } from 'react-router-dom'
import LocationData from "../../../Data/HomeData/LocationData.json"

export default function HomeOurLocation() {
    return (
        <div className="container mb-5 mt-5" >
            <div className="row" >
                <div className="col" >
                    <h4 className="home_ourlocation-title" > Partner with Kalpataru </h4>
                </div>
            </div>
            <div className="row justify-content-between" >
                <div className=" col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col" >
                    <p className="" > During the past 11 years, we built a successful track-record by curing the ailments that are not cured by modern medical practices. Over the years, we rendered treatment to more than 20, 000 patients from different walks of life, across the nation for chronic diseases and restored their health. </p>
                    <p> We adopt time-tested holistic approach by emphasizing on diet, herbal remedies, exercise, meditation, breathing, and therapy complemented by the techniques viz. Marma, Khiro used in ortho-neuro disorders and rejuvenate their health. Quite a number of print and electronic media captured our success stories.</p>
                    <Link to="" className="about-link" >Get Details</Link>
                    <Link to="" className="about-link m-2" >Enquiry</Link>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <img className="homeabout-image " src="images/home/partnerr.jpg" />
                </div>
                {/* {
                    LocationData.map((data) => (
                        <div className="col-xl-3 col-md-6" >
                            <div className="card mt-2 home-location-col ">
                                <div className="card-body">
                                    <h5 className="card-title home-location-title"> {data.title} </h5>
                                    <p className="card-subtitle home-location-text"> {data.address} </p>
                                    <p className="home-location-text" > {data.address1} </p>
                                    <p className="home-location-text" > {data.phone} </p>
                                </div>
                            </div>
                        </div>
                    ))
                } */}
            </div>
        </div>
    )
}
