import React from 'react'
import HomeOurProductsData from '../../../Data/HomeData/HomeOurProduct.json'

export default function HomeOurProducts() {
    return (
        <div className="container-fluid  home-product-container">
            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-xs-1 g-2 g-lg-3 justify-content-center" >
                <div className="col" >
                    <h3 className="home-product-main-title">Our Products</h3>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-xs-1 g-2 g-lg-1 justify-content-evenly">
                {
                    HomeOurProductsData.map((data, key) => (
                        <div key={key} className="col-xl-3 " >
                            <div className="card shadow-lg">
                                <img src={data.image} className="card-img-top home-product-image" alt="Loading.." />
                                <div className="card-body">
                                    <h5 className="card-title home-product-title"> {data.title} </h5>
                                    <p className="card-text home-product-text"> {data.text} </p>
                                    <a href="#" className="btn btn-primary homeservice-link "> {data.buttonText} </a>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}
