import React from 'react'
import { stethoscope } from 'react-bootstrap-icons';

export default function Neuro2() {
    return (
 <section id="neuro" class="home-section pt-3">
<div className='container'>
<div className='row'>
    <div className='col-sm-6 col-md-6'>
        <div className='wow fadeInUp'>
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv55DJ_GFuAA4vVkXeBb5SCl-x-UbM9IUgVw&usqp=CAU" className='img-responsive' alt="" />
        </div>
    </div>
    <div className='col-sm-3 col-md-3'>
        
        <div className='wow fadeInRight'>
        <div className='neuro-box'>
            <div className='neuro-icon'>
                <span><img className='prog-logo' src='images/home/branch.png' alt='logo'/> </span> 
            </div>
            <div className='neuro-desc'>
                <h5 className='h-light'>Brain stroke</h5>
                <p >lorem lorem lorem lorem lorem.</p>
            </div>
        </div>
        </div>
        
        <div className='wow fadeInRight'>
        <div className='neuro-box'>
            <div className='neuro-icon'>
            <span><img className='prog-logo' src='images/home/branch.png' alt='logo'/> </span>
            </div>
            <div className='neuro-desc'>
            <h5 className='h-light'>Back pain</h5>
                <p>lorem lorem lorem lorem lorem.</p>
            </div>
        </div>
        </div>
        <div className='wow fadeInRight' >
        <div className='neuro-box'>
            <div className='neuro-icon'>
            <span><img className='prog-logo' src='images/home/branch.png' alt='logo'/> </span> 
            </div>
            <div className='neuro-desc'>
                <h5 className='h-light'>Cervical pain</h5>
                <p>lorem lorem lorem lorem lorem.</p>
            </div>
        </div>
        </div>


    </div>
    <div className='col-sm-3 col-md-3'>
        
        <div className='wow fadeInRight'>
        <div className='neuro-box'>
            <div className='neuro-icon'>
            <span><img className='prog-logo' src='images/home/branch.png' alt='logo'/> </span>  
            </div>
            <div className='neuro-desc'>
                <h5 className='h-light'>Brain Tumour</h5>
                <p>lorem lorem lorem lorem lorem.</p>
            </div>
        </div>
        </div>
        
        <div className='wow fadeInRight'>
        <div className='neuro-box'>
            <div className='neuro-icon'>
            <span><img className='prog-logo' src='images/home/branch.png' alt='logo'/> </span> 
            </div>
            <div className='neuro-desc'>
                <h5 className='h-light'>Neck pain</h5>
                <p>lorem lorem lorem lorem lorem.</p>
            </div>
        </div>
        </div>
        <div className='wow fadeInRight'>
        <div className='neuro-box'>
            <div className='neuro-icon'>
            <span><img className='prog-logo' src='images/home/branch.png' alt='logo'/> </span>  
            </div>
            <div className='neuro-desc'>
                <h5 className='h-light'>Brain Infections</h5>
                <p>lorem lorem lorem lorem lorem.</p>
            </div>
        </div>
        </div>

    </div>
    
</div>		
</div>
</section>

          
     
    )
}
