import React from 'react'


export default function Women2() {
    return (
       
    <div>
       
   
    <section id="womenchild" className="womenchild">
      <div className="container" data-aos="fade-up">
  
        <div className="row">
          <div className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <h4 className="title"><a href="">Child&Mother health issues</a></h4>
              <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
              <h4 className="title"><a href="">BeforeChildBirth issues</a></h4>
              <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
            </div>
          </div>

          <div className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0">
            <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
              <h4 className="title"><a href="">AfterChildBirth issue</a></h4>
              <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
            </div>
          </div>

        

        </div>

      
      </div>
    
    </section><br/>
  
      </div>

       
    )
}