import React from 'react'
import HomeServiceData from '../../../Data/HomeData/HomeServiceData.json'

export default function HomeService() {
    return (
        <div className="container  progress-container">
            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-xs-1 g-2 g-lg-3 justify-content-center" >
            <div className="col" >
                <h3 className="home-services-main-title">Our Treatments</h3>
            </div>
            </div>
            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-xs-1 g-2 g-lg-3 justify-content-between">
                {
                    HomeServiceData.map((data,key) => (
                        <div key={key} className="col-xl-2 col-lg-4 " >
                            <div className="card card-image">
                                <img src={data.image} className="card-img-top homeservice-image" alt="Loading.." />
                                <div className="card-body">
                                    <h5 className="card-title homeservice-title"> {data.title} </h5>
                                    <p className="card-text homeservice-text"> {data.text} </p>
                                    <a href="#" className="btn btn-primary homeservice-link "> {data.buttonText} </a>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}
