import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Abouthospital from '../Container/About/Abouthospital'
import AboutHospitalOne from '../Container/About/AboutHospitalOne'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function AboutKalpataruHospitals() {
    return (

        <React.Fragment>
            <SEO title="Kalpataru || About Kalpataru Hospitals" />
            <TopNav />
            <Breadcrumb image="images/about/herb.jpg" title="Home" content="About Kalpataru Hospitals" />
            <Abouthospital />
            <AboutHospitalOne/>
            <Footer />
        </React.Fragment>

    )

}
