import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import MedicalTeamOne from '../Container/MedicalTeam/MedicalTeamOne'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function MedicalTeam() {
    return (
        <React.Fragment>
        <SEO title="Kalpataru || Medical Team"  />
        <TopNav/>
        <Breadcrumb image="images/about/herb.jpg" title="Home" content="Medical Team"/>
        <MedicalTeamOne/>
        <Footer/>
       </React.Fragment>
    )
}
