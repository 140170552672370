import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import BlogOne from '../Container/BlogContainer/BlogOne'
import Footer from '../Container/Footer/Footer'
import FranchiseOne from '../Container/FranchiseContainer/FranchiseOne'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function Blogs() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Blogs" />
            <TopNav />
            <Breadcrumb image="images/about/herb.jpg" title="Home" content="Blogs" />
            <BlogOne />
            <Footer />
        </React.Fragment>
    )
}
