import React from 'react'

export default function Progress() {
    return (
        <div className="container progress-container">
            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-xs-1 g-2 g-lg-3">
                {/* <div className="col align-self-center">
                    <div className="p-3 progress-title ">Our Achievements</div>
                </div> */}
                <div className="col">
                    <div className="p-3 border bg-light">
                        <div className="row" >
                            <div className="col-3 mt-3" >
                                <img className="prog-logo" src="images/home/branch.png" alt="logo" />
                            </div>
                            <div className="col-9" >
                                <h6 className="progress-title-one"> 9 </h6>
                                <p className="progress-time" >Total Branches </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="p-3 border bg-light">
                        <div className="row" >
                            <div className="col-3 mt-3" >
                                <img className="prog-logo" src="images/home/experience.png" alt="logo" />
                            </div>
                            <div className="col-9" >
                                <h6 className="progress-title-one"> 22 </h6>
                                <p className="progress-time" > Years of Excellence </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="p-3 border bg-light">
                        <div className="row" >
                            <div className="col-3 mt-3" >
                                <img className="prog-logo" src="images/home/hospital-bed.png" alt="logo" />
                            </div>
                            <div className="col-9" >
                                <h6 className="progress-title-one"> 100 </h6>
                                <p className="progress-time" > Beds </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="p-3 border bg-light">
                        <div className="row" >
                            <div className="col-3 mt-3" >
                                <img className="prog-logo" src="images/home/staff.png" alt="logo" />
                            </div>
                            <div className="col-9" >
                                <h6 className="progress-title-one"> 80 </h6>
                                <p className="progress-time" > Qualified Staff </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row" >
                <div className="col">
                    <div className="p-3 progress-title text-center ">
                        <Link className="about-link" to="/progresss" >Know More</Link>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
