import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function HomeAbout() {
    return (
        // <Container className="homeabout-main">
        //     <Row xl={12} lg={12} md={12} sm={12} xs={12} >
        //         <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        //             <h3 className="homeabout-title"> About Kalpataru Ayurveda </h3>
        //         </Col>
        //     </Row>
        //     <Row xl={12} lg={12} md={12} sm={12} xs={12}>
        //         <Col xl={6} lg={12} md={12} sm={12} xs={12}>
        //             <p>a nonprofit voluntary organization established 21 years ago in the year 1999 have been a setting example in creating awareness on individual ,social, moral, psychological and physiological developments thus contributing in healthy and well being of citizens.</p>
        //             <p>Due to the modernization and inclination towards western culture, India is losing its ancient heritage, Vedic culture and traditional educational system. Even after six decades of glorious independence India is still under the influence of slavery and bondage. Illiteracy and unemployment are the major factors affecting the global Indian economy. The influence of hectic day to day life is effecting the mental, physical and physiological development of an individual thus isolating him from happiness health and mental peace.</p>
        //         </Col>
        //         <Col xl={6} lg={6} md={6} sm={12} xs={12}>
        //             <Image className="homeabout-image" src="images/home/ayur-two.jpg" alt="Loading.." />
        //         </Col>
        //     </Row>
        // </Container>
        <div className="container homeabout-main">
            <div className="row" >
                <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12" >
                    <h3 className="homeabout-title"> About Kalpataru Ayurveda Hospital </h3>
                </div>
            </div>
            <div className="row justify-content-between">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <img className="homeabout-image" src="images/home/ayur-two.jpg" alt="Loading.." />
                </div>
                <div className=" col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 homeabout-col">
                    <p> Kalpataru Ayurveda Hospital is a nonprofit voluntary organization established 21 years ago in the year 1999 have been a setting example in creating awareness on individual ,social, moral, psychological and physiological developments thus contributing in healthy and well being of citizens.</p>
                    <p>Due to the modernization and inclination towards western culture, India is losing its ancient heritage, Vedic culture and traditional educational system. Even after six decades of glorious independence India is still under the influence of slavery and bondage. Illiteracy and unemployment are the major factors affecting the global Indian economy.</p>
                    <Link to="" className="about-link" >Know More</Link>
                </div>

            </div>
        </div>
    )
}
