import React from 'react'

function ShishuSwarnaOne() {
    return (
        <div className='container' >
            <div className="section_heading">
                <h1>SWARNA <span> BINDU </span> PRASHANAM</h1>
            </div>
            <div className='row mb-5' >
                <div className='col-xl-6' >
                    <p>
                        If both parents are suffering from Dm, 98 % of chances for their children to get the disease diabetes. If both parents are suffering from psoriasis then 23 % chances for their children for getting the disease psoriasis. 
                        Like wise there are many diseases which are getting inherited and suffered by the children due to the low immunity levels. SWARNA bindu Prashana increases the immunity levels in the body.
                    </p>
                    <p>
                    One child absorption will be more and attains all the milestones at the very regular intervals of time.the other not. Ayurvedic system of medicine claims that the reason behind is the medhya of that particular person.
                    </p>
                </div>
                <div className='col-xl-4' >
                    <img className='yoga_camp_img' src='images/events/swarna/swarna.png' />
                </div>
            </div>
        </div>
    )
}

export default ShishuSwarnaOne