import React from 'react'
import { stethoscope } from 'react-bootstrap-icons';

export default function Neuro3() {
    return (
       
           
    
     <section className="Kalpataru-second-ortho">

<div className="container-fluid">

    <div className="row mt-30">
        <div className="col-md-3 col-sm-6">
            <a href=""> <div className="ortho-card">
                <img src="https://www.narayanahealth.org/blog/wp-content/uploads/2019/11/shutterstock_1423084877.jpg" />
                <div className="ortho-content">
                    <p>Brain stroke</p>
                </div>

            </div></a>
        </div>
        <div className="col-md-3 col-sm-6">
            <a href=""><div className="ortho-card">
                <img src="https://www.narayanahealth.org/blog/wp-content/uploads/2019/11/shutterstock_1423084877.jpg" />
                <div className="ortho-content">
                    <p>Brain Tumour</p>
                </div>

            </div></a>
        </div>
        <div className="col-md-3 col-sm-6">
            <a href=""><div className="ortho-card">
                <img src="https://www.narayanahealth.org/blog/wp-content/uploads/2019/11/shutterstock_1423084877.jpg" />
                <div className="ortho-content">
                    <p>Headache</p>
                </div>

            </div></a>
        </div>
        <div className="col-md-3 col-sm-6">
            <a href=""><div className="ortho-card">
                <img src="https://www.narayanahealth.org/blog/wp-content/uploads/2019/11/shutterstock_1423084877.jpg" />
                <div className="ortho-content">
                    <p>Trauma</p>
                </div>

            </div></a>
        </div>
    </div>
</div>

</section>

    

          
     
    )
}
