import React from 'react';
import { ArrowRight } from 'react-bootstrap-icons';



export default function Treatment3() {
    return (
       
    <div>
      <div class="container">
<div class="row">
<div class="col-md-8">
<div className='orthocare-desc'>
<h5 class=" app-heading">About ArthoCare :-</h5>
<p> lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world
Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP.</p>
<h5 className='app-heading'>Causes :- </h5>
<ul className='treatment-ortho-ul'><li>lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world. </li>
 <li>Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP. </li></ul>
<h5 className='app-heading'>Symptoms :-</h5>
<ul className='treatment-ortho-ul'><li>lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world. </li>
<li>Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP.</li></ul>
<h5 class=" app-heading">Treatments :-</h5>
<ul className='treatment-ortho-ul'><li>1lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world. </li>
 <li>Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP.</li></ul>


</div>

</div>
       <div className='col-lg-4'>
                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="ortho-left-side">
                               <h4>Make An appointment </h4>
                                <ul>
                                    <li> Monday-friday <span> : 9.00-17.00</span></li>
                                    <li>Saturday<span style={{marginLeft: '42px'}}> : 9.00-16.00</span></li>
                                    <li>Sunday <span style={{marginLeft: '51px'}}> : closed</span></li>
                                   <b> <li>Nedd Urgent Help ? <span>9876543210</span></li></b>
                                </ul>
                                <div class="appointment-btn pt-10">
                                    <span></span>
                                    <a href="#" class="main-btn">Enroll Now</a>
                                </div>
                            </div> 
                        </div>
                        <div class="col-lg-12 col-md-6 pt-3">
                            <div class="ortho-card-secondpart ">
                                
                                <div class="ortho-image-part ">
                                    <div class="image">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS27b3JfFkJ4db4aqqP1-DNnD5QS3ODaJ_tjQ&usqp=CAU" alt="Image"/>
                                    </div>
                                    <div class="cont">
                                       <h4>ArthoCare</h4>
                                    </div>
                                </div><br/>
                                <div class="ortho-image-part ">
                                    <div class="image">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS27b3JfFkJ4db4aqqP1-DNnD5QS3ODaJ_tjQ&usqp=CAU" alt="Image"/>
                                    </div>
                                    <div class="cont">
                                       <h4>ArthoCare </h4>
                                    </div>
                                </div><br/>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
          </div>
  <br/>
         </div>

       
    )
}
