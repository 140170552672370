import { HashRouter as Router, Switch, Route, Redirect, useHistory} from "react-router-dom";
import Home from "./Components/Pages/Home";

// CSS
import "./assets/css/Style.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/swiper-bundle.min.css'
import AboutKalpataru from "./Components/Pages/AboutKalpataru";
import AboutKalpataruHospitals from "./Components/Pages/AboutKalpataruHospitals";
import AboutKalpataruStores from "./Components/Pages/AboutKalpataruStores";
import MedicalTeam from "./Components/Pages/MedicalTeam";
import Accomodation from "./Components/Pages/Accomodation";
import ArthoCare from "./Components/Pages/ArthoCare";
import Neurocare from "./Components/Pages/Neurocare";
import Obesity from "./Components/Pages/Obesity" ;
import Womenchildcare from "./Components/Pages/Womenchildcare";
import Beautycare from "./Components/Pages/Beautycare";
import Diagnosis from "./Components/Pages/Diagnosis";
import Contact from "./Components/Pages/Contact";
import AyurvedaExpo from "./Components/Pages/Events/AyurvedaExpo";
import YogaCamp from "./Components/Pages/Events/YogaCamp";
import ShishuSwarna from "./Components/Pages/Events/ShishuSwarna";
import BuyProducts from "./Components/Pages/BuyProducts";
import Franchise from "./Components/Pages/Franchise";
import Blogs from "./Components/Pages/Blogs";

// import About from "./Components/Pages/About";

function App() {
  const history = useHistory();

  return (
    <div className="App">
    <Router history={history} basename='/' >
       <Switch>
         {/* <Route exact path='/login' component={Login} /> */}
         <Route exact path='/' component={Home} />
         <Route path='/about' component={AboutKalpataru} />
         <Route path='/kalpataru/hospitals' component={AboutKalpataruHospitals}/>
         <Route path='/kalpataru/stores' component={AboutKalpataruStores}/>
         <Route path='/kalpataru/medical/team' component={MedicalTeam}/>
         <Route path='/kalpataru/accomodation' component={Accomodation}/>
         <Route path='/Treatments/Arthocare' component={ArthoCare} />
         <Route path='/Tretments/Neurocare' component={Neurocare} />
         <Route path= '/Treatments/Obesity' component={Obesity}/>
         <Route path= '/Treatments/Womenchildcare' component={Womenchildcare}/> 
         <Route path= '/Treatments/Beautycare' component={Beautycare}/>
         <Route path= '/Treatments/Diagnosis' component={Diagnosis}/>
         <Route path='/contact' component={Contact} />
         <Route path='/ayurveda/expo' component={AyurvedaExpo} />
         <Route path='/yogacamp' component={YogaCamp} />
         <Route path='/shishu/swarna' component={ShishuSwarna} />
         <Route path='/buy/products' component={BuyProducts} />
         <Route path='/franchise' component={Franchise} />
         <Route path='/blog' component={Blogs} />
         <Redirect from="*" to="/" />
       </Switch>
     </Router>
    </div>
  );
}

export default App;
