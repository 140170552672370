import React from 'react'
import { Link } from 'react-router-dom'
import data from "../../../Data/AboutData/AboutHospitalOne.json"

export default function AboutHospitalOne() {
    return (
        <div className='container mb-5' >
            <div className='row' >
                {
                    data.map((d) => (
                        <div className='col-lg-4' >
                            <div className='single-feature' >
                                <div className='feature-head' >
                                    {/* <img className='' src='images/home/experience.png' alt='Loading..' /> */}
                                    <h4> {d.title} </h4>
                                </div>
                                <div className='feature-content' >
                                    <p> {d.text} </p>
                                    <div className='feature-btn' >
                                        <Link href="#" className="f-btn"> {d.link} </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}
