import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import AboutOneData from "../../../Data/AboutData/AboutOne.json"

export default function AboutOne() {
    return (
        <Container fluid className='about-one-container-one' >
            <Container className="about-one-container" >
                <Row>
                    {
                        AboutOneData.map((data) => (
                            <Col >
                                <Card className="about-one-card" border="light">
                                    <Card.Header className="about-one-card-head text-center" > {data.title} </Card.Header>
                                    <Card.Body>
                                        <Card.Title> {data.subtitle} </Card.Title>
                                        <Card.Text>
                                            {data.text}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }

                </Row>
            </Container>
        </Container>
    )
}
