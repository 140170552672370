import React from 'react'


export default function Obesity3() {
    return (
       
        <div>
        <div className="container pt-5">
  <div className="row">
  <div className="col-md-8">
  <div className='orthocare-desc'>
  <h5 className=" app-heading">About Obesity :-</h5>
  <p> lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
  LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world
  Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
  Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP.</p>
  <h5 className='app-heading'>Causes :- </h5>
  <ul className='treatment-ortho-ul'><li>lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
  LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world. </li>
   <li>Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
  Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP. </li></ul>
  <h5 className='app-heading'>Symptoms :-</h5>
  <ul className='treatment-ortho-ul'><li>lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
  LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world. </li>
  <li>Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
  Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP.</li></ul>
  <h5 className=" app-heading">Treatments :-</h5>
  <ul className='treatment-ortho-ul'><li>1lorem lorem Chronic low back ache (CLBP) prevalence increase linearly from the third decade of life on untilthe 60years of age.
  LBP due to lumbar disc prolapsed is major cause of morbidity throughout the world. </li>
   <li>Lifetimeincidence of LBP is 50-70% with incidence of sciatica more than 40%.
  Sciatica is the most common debilitating condition and a serious disease of the nervous systemcauses CLBP.</li></ul>
  
  
  </div>
  
  </div>
         <div className='col-lg-4'>
                      <div className='row'>
                          <div className='col-lg-12 col-md-6'>
                              <div className='ortho-left-side'>
                                 <h4>Make An appointment </h4>
                                  <ul>
                                      <li> Monday-friday <span> : 9.00-17.00</span></li>
                                      <li>Saturday<span style={{marginLeft: '42px'}}> : 9.00-16.00</span></li>
                                      <li>Sunday <span style={{marginLeft: '51px'}}> : closed</span></li>
                                     <b> <li>Nedd Urgent Help ? <span>9876543210</span></li></b>
                                  </ul>
                                  <div className='appointment-btn pt-10'>
                                      <span></span>
                                      <a href="#" class="main-btn">Enroll Now</a>
                                  </div>
                              </div> 
                          </div>
                          <div className='col-lg-12 col-md-6 pt-3'>
                              <div className='ortho-card-secondpart '>
                                  
                                  <div className='ortho-image-part'>
                                      <div className='image'>
                                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS27b3JfFkJ4db4aqqP1-DNnD5QS3ODaJ_tjQ&usqp=CAU" alt="Image"/>
                                      </div>
                                      <div className='cont'>
                                         <h4>OverWeight</h4>
                                      </div>
                                  </div><br/>
                                  <div className='ortho-image-part '>
                                      <div className='image'>
                                          <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS27b3JfFkJ4db4aqqP1-DNnD5QS3ODaJ_tjQ&usqp=CAU" alt="Image"/>
                                      </div>
                                      <div className='cont'>
                                         <h4>under </h4>
                                      </div>
                                  </div><br/>
                              </div>
                          </div>
                      </div>
                  </div>
  
  
              </div>
            </div>
    <br/>
           </div>
       
    )
}
