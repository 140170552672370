import React from 'react'


export default function Obesity2() {
    return (
       
        <div className='container'>
        <div className='row'>
          <div className='col-sm-4 pt-3 '>
            <div className='card box-shadow'>
              <div className='obesity'>
              <img src='https://media.istockphoto.com/photos/weight-loss-success-picture-id153048232?k=20&m=153048232&s=612x612&w=0&h=B2fSduT5KGZ9m4VAKq24Ky4TwdVL4W61i8fEM1xe-K8=' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Over weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
          <div className='col-sm-4 pt-3'>
            <div className='card box-shadow'>
               <div className='obesity'>
              <img src='https://thumbs.dreamstime.com/b/fat-women-suffer-obesity-big-hamburger-apple-hands-junk-food-concept-woman-gray-background-73918587.jpg' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Under weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
          <div className='col-sm-4 pt-3'>
            <div className='card box-shadow'>
               <div className='obesity'>
              <img src='https://media.istockphoto.com/photos/weight-loss-success-picture-id153048232?k=20&m=153048232&s=612x612&w=0&h=B2fSduT5KGZ9m4VAKq24Ky4TwdVL4W61i8fEM1xe-K8=' class='card-img-top' alt='...'/></div>
              <div className='card-body'>
                <h4 className='card-title'>Over weight</h4>
                <p className='card-text'>Some quick example text to build on the card title and make up the bulk of the card's content make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-primary homeservice-link "> Know More </a>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      

       
    )
}
