import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import ProductOne from '../Container/BuyProductContainer/ProductOne'
import Footer from '../Container/Footer/Footer'
import FranchiseOne from '../Container/FranchiseContainer/FranchiseOne'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function Franchise() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Franchise" />
            <TopNav />
            <Breadcrumb image="images/about/herb.jpg" title="Home" content="Franchise" />
            <FranchiseOne />
            <Footer />
        </React.Fragment>
    )
}
