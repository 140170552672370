import React from 'react'


export default function Obesity1() {
    return (
       
    <div className="section_heading">
        <h1>Kalpataru <span>Obesity</span> Treatment</h1>
      </div>
      

       
    )
}
