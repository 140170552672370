import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Beauty1 from '../Container/Beautycare treatment/Beauty1'
import Beauty2 from '../Container/Beautycare treatment/Beauty2'
export default function Beautycare() {
    return (
       
      <React.Fragment>
      <SEO title="Kalpataru || Beautycare "  />
      <TopNav/>
      <Breadcrumb image="images/about/herb.jpg" title="Home" content="Beautycare"/>
      <Beauty1/>
      <Beauty2/>
    
      <Footer/>
     </React.Fragment>

       
    )
}
