import React from 'react'


export default function Diagnosis2() {
    return (
       
   
  <div>
       {/*  <!--General Diganosis--> */}
       <div className="diagnosis-banner1 ">
    
    <div className="container">
    <div className="row">
    <div className="col-md-6 ds-cont1  pt-5">
    <h2 className="challenges1">General Diganosis</h2>
    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur
      ex aliquid fuga eum quidemMagnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur
      ex aliquid fuga eum quidemMagnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. </p>
    </div>
    <div className="col-md-6">
    <img src="images/diagnosis/diagn.png" className='Diagnosisimg'/>
    </div>
    
    </div>
    </div>
    </div>
    {/* <!--end General Diganosis--> */}
   {/*  <!--Clinical Diganosis--> */}
   <div className="diagnosis-banner2 ">
    <div className="countersec">
          
<div className="container">
<div className="row">
<div className="col-md-6 pt-5">
<img src="images/diagnosis/diagn.png"  className='Diagnosisimg'/>
</div>
<div className="col-md-6 ds-cont">
<h2 className="challenges1"> Clinical Diganosis</h2>
<p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur
  ex aliquid fuga eum quidemMagnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur
      ex aliquid fuga eum quidemMagnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. </p>
</div>

</div>
</div>
</div>
</div>

{/* <!--end Clinical Diganosis--> */}
 {/*  <!--Traditional Diagnosis--> */}
<div className="diagnosis-banner3" >
    
    <div className="container">
    <div className="row">
    <div className="col-md-6 ds-cont1  pt-5">
    <h2 className="challenges1">Traditional Diagnosis</h2>
    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur
      ex aliquid fuga eum quidemMagnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur
      ex aliquid fuga eum quidemMagnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. </p>
    </div>
    <div className="col-md-6">
    <img src="images/diagnosis/diagn.png" className='Diagnosisimg'/>
    </div>
    
    </div>
    </div>
    </div>
     {/* <!--end Traditional Diagnosis--> */}
    </div>

       
    )
}
