import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function 
() {
    return (
        <Container fluid className="home-explore-container" >
            <Row>
                <Col>
                 <h3 className="home-explore-title" > Kalpataru Ayurveda Treatments </h3>
                 <p className="home-explore-text" > Over 15 years, providing you premium health care in a family-friendly environment. </p>
                 <Link className="btn btn-primary homeservice-link " >Explore More</Link>
                 <Link className="btn btn-primary homeservice-link m-2"  >Book Appointment</Link>
                </Col>
            </Row>
        </Container>
    )
}
