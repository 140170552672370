import React from 'react'
import Breadcrumb from '../../BreadCrumb/BreadCrumb'
import ShishuSwarnaOne from '../../Container/EventsContainer/ShishuSwarnaOne'
import ShishuSwarnaTwo from '../../Container/EventsContainer/ShishuSwarnaTwo'
import Footer from '../../Container/Footer/Footer'
import SEO from '../../Container/SEO/SEO'
import TopNav from '../../Container/TopNav/TopNav'

function ShishuSwarna() {
    return (
        <React.Fragment>
            <SEO title="Kalpataru || Shishu - Swarna" />
            <TopNav />
            <Breadcrumb image="images/about/herb.jpg" title="Home" content="Shishu - Swarna" />
            <ShishuSwarnaOne />
            <ShishuSwarnaTwo/>
            <Footer />
        </React.Fragment>
    )
}

export default ShishuSwarna