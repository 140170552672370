import React from 'react'


export default function Women4() {
    return (
       
    <div>
       
       <section className="beforechildbirth p-b-0">
                    
                    <div className="content-women">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="inner">
                                        <span>AfterChildBirth Issues</span>
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                                        Aperiam repudiandae, fugiat, voluptatum sequi quam obcaecati ut magni corporis 
                                        laborum iusto</p>
                                         <ol className="d-flex align-items-center">
                                            <li>
                                                <div>01</div>
                                                <h4>Headingpart1</h4>
                                            </li>

                                            <li>
                                                <div>02</div>
                                                <h4>Headingpart2</h4>
                                            </li>

                                            <li>
                                                <div>03</div>
                                                <h4>Headingpart3</h4>
                                            </li>

                                            <li>
                                                <div>04</div>
                                                <h4>Headingpart4</h4>
                                            </li>

                                            <li>
                                                <div>05</div>
                                                <h4>Headingpart5</h4>
                                            </li>

                                            <li>
                                                <div>06</div>
                                                <h4>Headingpart6</h4>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="image-wrapper">
                        <img src="https://media.istockphoto.com/photos/group-of-children-colouring-while-wearing-masks-picture-id1263061836?k=20&m=1263061836&s=612x612&w=0&h=lBrXPNSYdiPz4WEIVKoXLNZrlJDLKV_GpoufLslD8_Y="/>
                    </div>
                </section><br/>
  
      </div>

       
    )
}