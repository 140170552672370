import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import HomeContact from '../Container/HomePageContainer/HomeContact'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'

export default function Contact() {
  return (
    <React.Fragment>
      <SEO title="Kalpataru || Contact Us" />
      <TopNav />
      <Breadcrumb image="images/about/herb.jpg" title="Home" content="Contact Us" />
      <HomeContact/>
      <Footer />
    </React.Fragment>
  )
}
