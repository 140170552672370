import React from 'react'
import Breadcrumb from '../BreadCrumb/BreadCrumb'
import Footer from '../Container/Footer/Footer'
import SEO from '../Container/SEO/SEO'
import TopNav from '../Container/TopNav/TopNav'
import Womenchild1 from '../Container/Women&childcare treatment/womenchild1'
import Women2 from '../Container/Women&childcare treatment/Women2'
import Women3 from '../Container/Women&childcare treatment/women3'
import Women4 from '../Container/Women&childcare treatment/women4'




export default function Womenchildcare() {
    return (
       
      <React.Fragment>
      <SEO title="Kalpataru ||Women & childcare "  />
      <TopNav/>
      <Breadcrumb image="images/about/herb.jpg" title="Home" content="Women &childcare"/>
      <Womenchild1/>
      <Women2/>
      <Women3/>
      <Women4/>
      <Footer/>
     </React.Fragment>

       
    )
}
