import React from 'react'
import { Col, Container, Form, Row, Button, InputGroup } from 'react-bootstrap'


export default function Treatment1() {
    return (

        <div>
            <section className="Kalpataru-content1">
                <div className="container">
                    <div className="Kalpataru-ortho">
                        <div className="row ">
                            <div className="col-md-8 order-md-1 order-2">
                                <h4>ArthoCare</h4>
                                <p className="para-text">lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem </p>
                                <p className="para-text">lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem lorem lorem lorek lorm loeeh  lorem
                                </p>
                                <p></p>
                            </div>
                            <div className="col-md-4 order-1 text-center">

                                <div className="abtImg">
                                    <img src="https://sgayurveda.com/images/a2.gif" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Kalpataru-second-ortho">

                <div className="container-fluid">

                    <div className="row mt-30">
                        <div className="col-md-3 col-sm-6">
                            <a href=""> <div className="ortho-card">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSy7umNOD3Bjl5jWQ7Q96YHOpKFGScDohRfQ&usqp=CAU" />
                                <div className="ortho-content">
                                    <p>ArthoCare</p>
                                </div>

                            </div></a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href=""><div className="ortho-card">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSy7umNOD3Bjl5jWQ7Q96YHOpKFGScDohRfQ&usqp=CAU" />
                                <div className="ortho-content">
                                    <p>ArthoCare</p>
                                </div>

                            </div></a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href=""><div className="ortho-card">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSy7umNOD3Bjl5jWQ7Q96YHOpKFGScDohRfQ&usqp=CAU" />
                                <div className="ortho-content">
                                    <p>ArthoCare</p>
                                </div>

                            </div></a>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <a href=""><div className="ortho-card">
                                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSy7umNOD3Bjl5jWQ7Q96YHOpKFGScDohRfQ&usqp=CAU" />
                                <div className="ortho-content">
                                    <p>ArthoCare</p>
                                </div>

                            </div></a>
                        </div>
                    </div>
                </div>

            </section><br />





        </div>
    )
}