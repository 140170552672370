import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
import Data from "../../../Data/Events/YogaCampOneData.json"
import { Button } from "react-bootstrap";

SwiperCore.use([Autoplay]);

export default function YogaCampOne() {
    return (
        <div className="container">
            <div className="section_heading">
                <h1>Kalpataru <span> Yoga</span> Camps</h1>
            </div>
            <div className="row mb-5 mt-4" >
                <div className="col-xl-6" >
                    <p className="yoga_camp_p" > 5 DAYS BLISSFUL YOGA RETREAT IN GOA, INDIA </p>
                    <Button className="yoga_camp_btn mb-3"> Book Now </Button>
                </div>
                <div className="col-xl-6" >
                    <Swiper
                        effect="fade"
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, EffectFade]}
                    >
                        {Data.map((d) => (
                            <SwiperSlide className="yoga_camp_slide">
                                <img src={d.image} alt="Loading.." />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <div className="row mb-5" >
                <div className="col-xl-6 col-sm-12 col-xs-12" >
                    <div className="card bg-dark text-white mt-3 ">
                        <img src="images/events/yoga/yogtwo.jpg" className="card-img yoga_img" alt="..." />
                        <div className="card-img-overlay expo_one_row_bg">
                            <h5 className="card-title yoga_title"> 7 Days Rejuvenation, Meditation, and Yoga Retreat </h5>
                            {/* <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}
                            <Button className='expo_one_btn mb-2'> Book Now </Button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6" >
                    <div className="card bg-dark text-white mt-3 ">
                        <img src="images/events/yoga/yogfour.jpg" className="card-img yoga_img" alt="..." />
                        <div className="card-img-overlay expo_one_row_bg">
                            <h5 className="card-title yoga_title"> 7 Days Blissful Meditation and Yoga Retreat </h5>
                            {/* <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p> */}
                            <Button className='expo_one_btn mb-2'> Book Now </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
